import MicroModal from "micromodal";

const resetSteps = () => {
  const steps = form.querySelectorAll(".form-step");
  const prevBtn = form.querySelector("#prevBtn");
  const nextBtn = form.querySelector("#nextBtn");

  Array.from(steps).forEach(step => {
    if (step.classList.contains("active")) step.classList.remove("active")
  })

  if (steps && steps.length > 0) {
    steps[0].classList.add("active");
    prevBtn.classList.add("hide")
    nextBtn.innerHTML = "Próxima";
  }
}

const validRadioChecked = nodeList => {
  let isValid;

  isValid =
    Array.from(nodeList).find(option => option.checked) === undefined
      ? false
      : true;

  return isValid;
};

const showStep = (currentStep, domElement) => {
  const steps = domElement.querySelectorAll(".form-step");
  const prevBtn = domElement.querySelector("#prevBtn");
  const nextBtn = domElement.querySelector("#nextBtn");
  steps[currentStep].classList.add("active");

  currentStep === 0
    ? prevBtn.classList.add("hide")
    : prevBtn.classList.remove("hide");

  if (currentStep === steps.length - 1) {
    nextBtn.innerHTML = "Enviar Votos";
  } else {
    nextBtn.innerHTML = "Próxima";
  }
};

const nextPrevStep = (currentStep, number, domElement) => {
  const steps = domElement.querySelectorAll(".form-step");
  const options = steps[currentStep].querySelectorAll('input[type="radio"]');
  const lastStep = currentStep === steps.length - 1;

  if (number === 1 && !validRadioChecked(options) && !lastStep) {
    if (currentStep !== steps.length - 1) {
      MicroModal.show("modal-2");
    }

    return {
      currentStep,
      status: false
    };
  }

  if (!lastStep || number === -1) {
    steps[currentStep].classList.remove("active");
    currentStep = currentStep + number;
  }

  if (number === 1 && lastStep) {
    form.dispatchEvent(new Event('submit'))
    
    return {
      currentStep,
      status: true
    };
  }

  showStep(currentStep, domElement);

  return {
    currentStep,
    status: true
  };;
};

export { resetSteps, nextPrevStep, showStep };

