import axios from "axios";
import { API, TOKEN } from "./env";

const isCpfAlreadyUsed = (input, fase) =>
  axios
    .get(`${API}/validate?cpf=${input}&fase=${fase}`, { headers: { Authorization: TOKEN } })
    .then(response => {
      if (response) {
        return undefined;
      }
    })
    .catch(err => err.response.data.message);

const isEmailAlreadyUsed = (input, fase) =>
  axios
    .get(`${API}/validate?email=${input}&fase=${fase}`, {
      headers: { Authorization: TOKEN }
    })
    .then(response => {
      if (response) {
        return undefined;
      }
    })
    .catch(err => err.response.data.message);

const isPhoneAlreadyUsed = (input, fase) =>
  axios
    .get(`${API}/validate?telefone=${input}&fase=${fase}`, {
      headers: { Authorization: TOKEN }
    })
    .then(response => {
      if (response) {
        return undefined;
      }
    })
    .catch(err => err.response.data.message);

export { isCpfAlreadyUsed, isEmailAlreadyUsed, isPhoneAlreadyUsed };
