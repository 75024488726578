import queryString from "query-string";
import axios from "axios";
import { VOTE_INFO, TOKEN } from "./env";

const getOrigin = () => queryString.parse(window.location.search);

const getEditionInfo = () =>
  axios.get(VOTE_INFO, { headers: { Authorization: TOKEN } }).then(response => {
    const result = response.data.results[0];

    const vote = result.components.find(({ type }) => type === "voting");

    return vote;
  });

export { getOrigin, getEditionInfo };
