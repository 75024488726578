import axios from "axios";
import objectFitImages from 'object-fit-images';
import VMasker from "vanilla-masker";
import { afterEndMessage, beforeStartMessage, createFirstPhaseForm, createSecondPhaseForm } from "./assets/js/domRenders";
import { API, SITEKEY, TOKEN } from "./assets/js/env";
import { handleFormSubmit } from "./assets/js/form";
import { getEditionInfo } from "./assets/js/request";
import "./assets/styles/style.scss";

objectFitImages();

const recaptchaCallback = () =>
  grecaptcha.render(document.getElementById("recaptchaContainer"), {
    sitekey: SITEKEY
  });

getEditionInfo()
  .then(validatorsInfo => {
    const form = document.getElementById("form");
    form.addEventListener("submit", () =>
      handleFormSubmit(event, validatorsInfo)
    );

    const renderQuestions = questionsData => {
      const startDateArr = validatorsInfo.validade.inicio.split(/[^0-9]/)
      const endDateArr = validatorsInfo.validade.fim.split(/[^0-9]/)

      const voteStart = new Date(
        startDateArr[0],
        startDateArr[1] - 1,
        startDateArr[2],
        startDateArr[3],
        startDateArr[4],
        startDateArr[5]
      )

      const voteEnd = new Date(
        endDateArr[0],
        endDateArr[1] - 1,
        endDateArr[2],
        endDateArr[3],
        endDateArr[4],
        endDateArr[5]
      )

      const currentDate = new Date();

      const voteInProgress = currentDate > voteStart && currentDate < voteEnd;

      if (voteInProgress) {
        if (validatorsInfo.fase === "1") {
          form.appendChild(createFirstPhaseForm(questionsData));
          window.recaptchaCallback = recaptchaCallback;
          recaptchaCallback();
          VMasker(form.querySelector("#telefone")).maskPattern(
            "(99) 99999-9999"
          );
          VMasker(form.querySelector("#cpf")).maskPattern("999.999.999-99");
        }
        if (validatorsInfo.fase === "2") {
          form.appendChild(
            createSecondPhaseForm(questionsData, validatorsInfo)
          );
          recaptchaCallback();
          VMasker(form.querySelector("#telefone")).maskPattern(
            "(99) 99999-9999"
          );
          VMasker(form.querySelector("#cpf")).maskPattern("999.999.999-99");
        }
      } else {
        if (currentDate < voteStart) {
          form.innerHTML = beforeStartMessage();
        }

        if (currentDate > voteEnd) {
          form.innerHTML = afterEndMessage();
        }
      }
    };

    const getFormFields = () => {
      try {
        axios
          .get(
            `${API}?fase=${validatorsInfo.fase}&ano=${
              validatorsInfo.ano
            }&orderby=ordem&sort=asc`,
            {
              headers: { Authorization: TOKEN }
            }
          )
          .then(response => renderQuestions(response.data));
      } catch (err) {
        throw new Error(err);
      }
    };

    getFormFields();
  })
  .finally(() => {
    document.getElementById("loader").style.display = "none";
  });
