import isValidCpf from "@brazilian-utils/is-valid-cpf";
import axios from "axios";
import MicroModal from "micromodal";
import { API, TOKEN } from "./env";
import { getOrigin } from "./request";
import { resetSteps } from "./steps";
import StepStore from "./store/StepStore";
import { isCpfAlreadyUsed, isEmailAlreadyUsed, isPhoneAlreadyUsed } from "./validators";

const isValidEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.]{1}[a-z]{2,4}$/.test(email);

const isQuestion = element => element.dataset.question;

const isValidElement = element => element.name && element.value;

const isValidValue = element =>
  !["checkbox", "radio"].includes(element.type) || element.checked;

const formToJSON = elements =>
  [].reduce.call(
    elements,
    (data, element) => {
      const isValidQuestion =
        isValidElement(element) && isValidValue(element) && isQuestion(element);

      const isValidRegister =
        isValidElement(element) &&
        isValidValue(element) &&
        !isQuestion(element);

      if (isValidQuestion) {
        data["perguntas"] = (data["perguntas"] || []).concat({
          id: element.name,
          reposta: element.value.toUpperCase()
        });
      }

      if (isValidRegister) {
        data["cadastro"] = data["cadastro"] || {};

        data["cadastro"][element.name] = (element.id !== 'emailoptin' && element.id !== 'acceptTerms') ? element.value : element.checked;
      }

      return data;
    },
    {}
  );

const sendData = (jsonData, btnSubmit) => {
  axios
    .post(API, jsonData, { headers: { Authorization: TOKEN } })
    .then(response => {
      if (response.status === 200) {
        form.reset();
        grecaptcha.reset();
        MicroModal.show("modal-1");
      }

      form.querySelector('#recaptchaError').classList.remove("visible");
      btnSubmit.disabled = false;
      
      StepStore.set(0);
      resetSteps();
    })
    .catch(err => {
      console.log(err)
      form.querySelector('#recaptchaError').innerHTML = 'Não foi possível enviar o formulário. Por favor, revise as repostas e tente novamente. '
      form.querySelector('#recaptchaError').classList.add("visible");
      btnSubmit.disabled = false;
    });
};

const checkCpf = async fase => {
  const cpf = form.querySelector("#cpf");
  const cpfError = form.querySelector("#cpfError");
  const cpfValue = cpf.value.replace(/[^\d]/g, "");

  try {
    const response = await isCpfAlreadyUsed(cpfValue, fase);

    if (response && response !== undefined) {
      cpfError.innerHTML = response;

      if (!cpfError.classList.contains("visible")) {
        cpfError.classList.add("visible");
      }

      return true;
    }

    cpfError.classList.remove("visible");

    return false;
  } catch (err) {
    console.log(err);
    return true;
  }
};

const checkEmail = async fase => {
  const email = form.querySelector("#email");
  const emailError = form.querySelector("#emailError");

  try {
    const response = await isEmailAlreadyUsed(email.value, fase);
    if (response && response !== undefined) {
      emailError.innerHTML = response;

      if (!emailError.classList.contains("visible")) {
        emailError.classList.add("visible");
      }

      return true;
    }

    emailError.classList.remove("visible");

    return false;
  } catch (err) {
    console.log(err);
    return true;
  }
};

const checkPhone = async fase => {
  const telefone = form.querySelector("#telefone");
  const telefoneError = form.querySelector("#telefoneError");
  const phoneValue = telefone.value.replace(/[^\d]/g, "");

  try {
    if (telefone.value.length < 10) {
      telefoneError.classList.add("visible");
      telefoneError.innerText = "Telefone inválido!";

      return true;
    }

    const response = await isPhoneAlreadyUsed(phoneValue, fase);

    if (response && response !== undefined) {
      telefoneError.innerHTML = response;

      if (!telefoneError.classList.contains("visible")) {
        telefoneError.classList.add("visible");
      }

      return true;
    }

    telefoneError.classList.remove("visible");

    return false;
  } catch (err) {
    console.log(err);
    return true;
  }
};

const isFormValid = async ({ cpfunico, emailunico, telefoneunico, fase }, btnSubmit) => {
  btnSubmit.disabled = true;
  const cpf = form.querySelector("#cpf");
  const cpfError = form.querySelector("#cpfError");
  const email = form.querySelector("#email");
  const emailError = form.querySelector("#emailError");
  const recaptchaError = document.getElementById("recaptchaError");
  const recaptchaResponse = grecaptcha.getResponse();
  const acceptTerms = form.querySelector("#acceptTerms");
  const acceptTermsError = form.querySelector("#acceptTermsError");

  if (!acceptTerms.checked) {
    acceptTermsError.classList.add("visible");
    btnSubmit.disabled = false;
    return false;
  } else {
    acceptTermsError.classList.remove("visible");
  }

  if (recaptchaResponse.length === 0) {
    recaptchaError.classList.add("visible");
    btnSubmit.disabled = false;

    return false;
  }

  if (recaptchaError.classList.contains("visible")) {
    recaptchaError.classList.remove("visible");
  }

  if (!isValidEmail(email.value)) {
    emailError.innerHTML = 'Email inválido!';
    emailError.classList.add("visible");
    btnSubmit.disabled = false;

    return false;
  } else {
    emailError.classList.remove("visible");
  }

  if (!isValidCpf(cpf.value)) {
    cpfError.innerHTML = 'CPF inválido!';
    cpfError.classList.add("visible");
    btnSubmit.disabled = false;

    return false;
  } else {
    cpfError.classList.remove("visible");
  }

  let cpfUsed;
  let emailUsed;
  let phoneUsed;

  if (cpfunico) {
    cpfUsed = await checkCpf(fase);
  }

  if (emailunico) {
    emailUsed = await checkEmail(fase);
  }

  if (telefoneunico) {
    phoneUsed = await checkPhone(fase);
  }

  if (cpfUsed || emailUsed || phoneUsed) {
    btnSubmit.disabled = false;
    return false;
  }

  return true;
};

const cleanField = input => {
  input = input.replace(/^[\s]+|[^\w\s\d\u00C0-\u00FF]+|[\s]+$/g, "");
  input = input.replace(/[\s]{2,}/g, " ");
  input = input.replace(/^[\s]+|[\s]+$/g, "");
  
  return input;
}

const handleFormSubmit = async (event, validatorsInfo) => {
  event.preventDefault();

  const btnSubmit = form.querySelector("#btnSubmit") || form.querySelector("#nextBtn")
  const { fase, ano } = validatorsInfo;
  let { origem } = getOrigin();
  
  origem = origem ? origem : "SBT";

  Array.from(form.elements).map(input => {
    if (input.id !== "email") {
      input.value = cleanField(input.value)
    }

    if (input.id === "telefone") {
      input.value = input.value.replace(/[^\d]/g, "");
    }

    if (input.id === "cpf") {
      input.value = input.value.replace(/[^\d]/g, "");
    }
  });

  if (await isFormValid(validatorsInfo, btnSubmit)) {
    const jsonForm = formToJSON(form.elements);
    
    const data = { ...{ fase, ano, origem }, ...jsonForm };
    
    sendData(data, btnSubmit);
  }
};

export { handleFormSubmit };

