import { nextPrevStep, showStep } from "./steps";
import StepStore from "./store/StepStore";

const alphabeticallySort = (current, next) => {
  if (current.label.toLowerCase() < next.label.toLowerCase()) return -1;
  if (current.label.toLowerCase() > next.label.toLowerCase()) return 1;
  return 0;
};

const renderNextPrevButtons = () => {
  return `<div class="form-step__buttons">
      <button type="button" id="prevBtn" class="button-prev-step">Anterior</button>
      <button type="button" id="nextBtn" class="button-next-step">Próxima</button>
    </div>`;
};

const createUserDataFields = () => {
  const registerSection = document.createElement("section");
  const html = `<div class="form-control">
      <label class="form-label">Nome<span class="required"> *</span></label>
      <input
        type="text"
        class="form-input"
        autocomplete="off"
        required
        name="nome"
        placeholder="Digite seu nome"
        data-value-missing="Este campo é obrigatório!"
        id="nome"
        minlength="3"
        maxlength="250"
      />
      
      <span class="form-error" id="nomeError"></span>
    </div>
    
    <div class="form-control">
      <label class="form-label">Email<span class="required"> *</span></label>
      <input
        type="email"
        class="form-input"
        autocomplete="off"
        required
        name="email"
        placeholder="Digite seu email"
        id="email"
        minlength="3"
        maxlength="250"
      />

      <span class="form-error" id="emailError">Email inválido!</span>
    </div>
    
    <div class="form-control">
      <label class="form-label">CPF<span class="required"> *</span></label>
      <input
        type="text"
        class="form-input"
        autocomplete="off"
        required
        name="cpf"
        placeholder="Digite seu CPF"
        minlength="11"
        maxlength="14"
        id="cpf"
        onkeyup="numbersOnly(this)"
      />
      
      <span class="form-error" id="cpfError">CPF inválido!</span>
    </div>
    
    <div class="form-control">
      <label class="form-label">Telefone<span class="required"> *</span></label>
      <input
        type="text"
        class="form-input"
        autocomplete="off"
        required
        name="telefone"
        placeholder="Digite seu telefone com o DDD"
        minlength="12"
        maxlength="15"
        id="telefone"
        onkeyup="numbersOnly(this)"
      />

      <span class="form-error" id="telefoneError">Telefone inválido!</span>
    </div>
    
    <div class="form-control">
      <input
        id="emailoptin"
        type="checkbox"
        class="form-checkbox"
        name="emailoptin"
      />
      <label for="emailoptin" class="form-label">
        Aceito receber informações e promoções do SBT, das demais empresas do Grupo Silvio Santos e de seus parceiros comerciais.
      </label>
    </div>


    <div class="form-control">
      <input
        id="acceptTerms"
        type="checkbox"
        class="form-checkbox"
        name="acceptTerms"
        required
      />
      <label class="form-label" id="labelOptin" for="defaultCheck2">
        Ao criar o cadastro, eu declaro que li e aceito os <a href="https://www.sbt.com.br/termos-de-uso" target="_blank">Termos de Uso</a> e as <a href="https://www.sbt.com.br/politica-de-privacidade" target="_blank">Políticas de Privacidade</a> do SBT. <span class="required"> *</span>
      </label>
      <span class="form-error" id="acceptTermsError">Você deve aceitar os termos!</span>
    </div>    
    
    <div class="recaptcha">
      <div id="recaptchaContainer"></div>

      <span class="form-error" id="recaptchaError">Confirme que você não é um robô</span>
    </div>`;

  registerSection.innerHTML = html;

  return html;
};

const createFirstPhaseForm = questionsData => {
  const sectionFirstPhase = document.createElement("section");
  sectionFirstPhase.classList.add("first-phase");

  sectionFirstPhase.innerHTML += `<h2 class="form-title">Formulário</h2>
    <p class="required-hint"><span class="required"> *</span> Campo obrigatório</p>`;

  questionsData.forEach(question => {
    sectionFirstPhase.innerHTML += `<div class="form-control">
        <label class="form-label">${
          question.pergunta
        }<span class="required"> *</span></label>
        <input
          type="text"
          class="form-input"
          autocomplete="off"
          required
          name="${question.id}"
          id="${question.id}"
          data-question="true"
          minlength="3"
          maxlength="250"
        />

        <span class="form-error" id="${question.id}Error"></span>
      </div>`;
  });

  sectionFirstPhase.innerHTML += createUserDataFields();

  sectionFirstPhase.innerHTML += `<button type="submit" id="btnSubmit">Enviar</button>`;

  return sectionFirstPhase;
};

const createSecondPhaseForm = (questionsData, { ano }) => {
  const sectionSecondPhase = document.createElement("section");
  sectionSecondPhase.classList.add("second-phase");

  let html;

  html = `<p class="form-description">Indique abaixo os Artistas e Programas de TV que na sua opinião mais se destacaram no ano de ${ano}.</p>`;

  questionsData.forEach((question, index) => {
    html += `<div class="form-step">
        <p>Categoria <span class="current-step">${index + 1}</span> de ${
      questionsData.length
    }</p>
        <p class="step-question">${question.pergunta}</p>
      
    
    <section class="list-container">
      <div class="list-content-list" id="list-${index}">`;

    let answears = "";

    if (question.respostas.length > 0) {
      question.respostas.forEach(resposta => {
        answears += `
          <div class="list-item">
            <input type="radio" id="${resposta.id}" value="${
          resposta.value
        }" name="${question.id}" data-question="true">
            <label for="${resposta.id}">${resposta.label}
              <div class="list-item-image">
                <img src="${resposta.img}" alt="${resposta.label}"/>
              </div>
            </label>
          </div>
        `;
      });
    }

    html += answears;

    html += `</div>
    </section>
    </div>`;
  });

  html += `<div class="form-step about">
        <p class="step-question">Informe seus dados</p>
        ${createUserDataFields()}
      </div>`;

  html += renderNextPrevButtons();

  sectionSecondPhase.innerHTML = html;

  showStep(StepStore.get(), sectionSecondPhase);

  const prevBtn = sectionSecondPhase.querySelector("#prevBtn");
  const nextBtn = sectionSecondPhase.querySelector("#nextBtn");

  prevBtn.addEventListener("click", () => {
    const step = nextPrevStep(StepStore.get(), -1, sectionSecondPhase)
    if (step.status) {
      StepStore.set(step.currentStep)
    }
  });
  nextBtn.addEventListener("click", () => {
    const step = nextPrevStep(StepStore.get(), 1, sectionSecondPhase)
    if (step.status) {
      StepStore.set(step.currentStep)
      console.log(StepStore.get())
    }
  });

  return sectionSecondPhase;
};

const beforeStartMessage = () => {
  return ` <section class="not-in-progress">
    <i class="far fa-frown"></i>
    <h1>A votação ainda não foi iniciada!</h1>
  </section>`;
};

const afterEndMessage = () => {
  return ` <section class="not-in-progress">
    <i class="far fa-frown"></i>
    <h1>A votação já foi encerrada!</h1>
  </section>`;
};

export { createFirstPhaseForm, createSecondPhaseForm, beforeStartMessage, afterEndMessage };

